<template>
  <div class="pa-5">
    <v-form
      ref="registerForm"
      v-model="valid"
      lazy-validation
      @submit.prevent="onSubmit"
    >
      <v-row class="justify-center">
        <v-col
          cols="12"
          lg="12"
        >
          <v-row v-if="user != null">
            <v-col cols="12">
              <photo-widget />
            </v-col>

            <v-col
              cols="12"
              lg="6"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Pseudo") }}
              </p>
              <v-text-field
                v-model="user.pseudo"
                placeholder="Pseudo"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Salutation") }}<span class="red--text">*</span>
              </p>
              <v-select
                v-model="user.salutation"
                dense
                :items="salutations"
                :rules="salutationRules"
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t('Prénom') }}
              </p>
              <v-text-field
                v-model="user.first_name"
                :rules="firstNameRules"
                placeholder="John"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t('Nom') }}
              </p>
              <v-text-field
                v-model="user.last_name"
                :rules="lastNameRules"
                placeholder="Doe"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Nom d'affichage") }}
              </p>
              <v-text-field
                v-model="user.name"
                :rules="nameRules"
                placeholder="John Doe"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Téléphone") }}
              </p>
              <v-text-field
                v-model="user.tel"
                :rules="telRules"
                placeholder=""
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Date de naissance") }}
              </p>
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="user.dob"
                    label=""
                    placeholder=""
                    outlined
                    dense
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="user.dob"
                  no-title
                  :active-picker.sync="activePicker"
                  :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                  min="1950-01-01"
                  @input="dateMenu = false"
                  @change="save"
                />
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Adresse") }}<span class="red--text">*</span>
              </p>
              <v-text-field
                v-model="user.address"
                :rules="addressRules"
                placeholder=""
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Code postal") }}<span class="red--text">*</span>
              </p>
              <v-text-field
                v-model="user.zip"
                placeholder=""
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Région /État") }}
              </p>
              <v-text-field
                v-model="user.region"
                placeholder=""
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Pays") }}
              </p>
              <v-select
                v-model="user.country_code"
                dense
                :items="countries"
                outlined
                item-value="code"
                item-text="name"
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Ville") }}
              </p>
              <v-text-field
                v-model="user.city"
                placeholder=""
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
            >
              <p class="text-14 mb-1">
                {{ $t("Rédigez vos propres conditions de Ventes*") }}
              </p>
              <v-textarea
                v-model="user.introduction"
                placeholder=""
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              lg="6"
              class="py-0"
            >
              <v-switch
                v-model="user.make_country_visible"
                placeholder=""
                :true-value="true"
                :false-value="false"
                :label="$t('Montrez ma Ville au public')"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="12"
              lg="12"
            >
              <div class="mb-4 ">
                <v-btn
                  :disabled="!valid"
                  color="primary"
                  class="text-capitalize font-600"
                  type="submit"
                  :loading="loading"
                >
                  {{ $t("Enregistrez") }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!--      <div class="mb-4">
        <v-checkbox
          v-model="checkbox"
          :rules="termsRules"
        >
          <template v-slot:label>
            <div>
              {{ $t("En vous inscrivant, vous acceptez les") }}

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <a
                    target="_blank"
                    href="#"
                    @click.stop
                    v-on="on"
                  >
                    {{ $t("Conditions générales d'utilisation") }}
                  </a>
                </template>
                {{ $t("Ouvre dans une nouvelle fenêtre") }}
              </v-tooltip>
            </div>
          </template>
        </v-checkbox>
      </div>-->
    </v-form>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="snackBarColor"
    >
      {{ snackBarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn

          text
          v-bind="attrs"
          @click="isSnackbarBottomVisible = false"
        >
          {{ $t("Fermer") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import { i18n } from '@/plugins/i18n'
  import { mapActions, mapGetters, mapState } from 'vuex'

  import FileUpload from '../../components/file_upload.vue'
  import PhotoWidget from './PhotoWidget.vue'

  export default {
    components: { FileUpload, PhotoWidget },

    data: vm => {
      return {
        dateMenu: false,
        activePicker: null,
        valid: false,
        checkbox: false,
        loading: false,
        isPasswordVisible: false,
        snackBarColor: null,
        isSnackbarBottomVisible: false,
        snackBarMessage: '',
        name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',

        errorMessages: { name: 'Est requis' },
        salutationRules: [v => !!v || i18n.t('Requis.')],
        termsRules: [v => !!v || i18n.t('Accepter les conditions générales')],
        nameRules: [v => !!v || i18n.t('Le nom est requis')],
        firstNameRules: [v => !!v || i18n.t('Le prénom est requis')],
        lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis')],
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],
        passwordRules: [v => !!v || i18n.t('Le mot de passe est requis')],
        telRules: [v => !!v || i18n.t('Le numéro de téléphone est requis.')],
        addressRules: [v => !!v || i18n.t('L\'adresse est requise.')],
        date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        user: {
          dob: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        },
      }
    },
    computed: {
      ...mapGetters(['getCartProducts', 'unreadNotifications']),
      ...mapState({
        userData: 'user',
        salutations: 'salutations',
        countries: 'countries',
        displayNotification: 'display_notification',
      }),
      computedDateFormatted () {
        return this.formatDate(this.user.user.dob || this.date1)
      },
      date () {
        return this.user.dob
      },
    },
    watch: {
      userData (val) {
        this.user = val
      },
      date (val) {
        this.dateFormatted = this.formatDate(this.user.user.dob || this.date1)
      },
      dateMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
    },
    created () {
      this.user = this.$store.state.user
    },
    methods: {
      ...mapActions(['pushItem']),
      save (date) {
        this.$refs.dateMenu.save(date)
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      onSubmit () {
        const isFormValid = this.$refs.registerForm.validate()

        if (!isFormValid) return
        this.loading = true
        // eslint-disable-next-line no-undef
        axios({
          method: 'post',
          url: '/users',
          data: this.user,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        }).catch(error => {
          // this.loading = false
          this.loading = false
          console.log(error)
          // eslint-disable-next-line consistent-return
        }).then((response) => {
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              this.errorMessages = { ...response.data.errors }
            } else {
              this.toast(response.data.message, 'red')

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return false
          }
          //localStorage.setItem('userData', JSON.stringify(response.data))
          this.toast(this.$t('Informations enregistrées avec succès'), 'success')
        })
          .then(() => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to Register!')
            console.log('error :>> ', error.response || '')
            // this.errorMessages = error.response.data.error
          })
      },
      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sign-up-card {
    width: 500px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 8px;
    margin: 2rem auto;
    box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
    @media(max-width: 500px){
      width: 100%;
    }
    .sign-up-card-container {
      padding: 3rem 3.75rem 0px;
      @media(max-width: 500px){
        padding: 3rem 1rem 0px;
      }
    }
  }
  .box-sidebar {
    .sidebar-slider {
      .v-messages {
        display: none;
      }
    }
  }
</style>
