<template>
  <div class="pa-5">
    <div
      id="kyclist"
      class="row"
    >
      <div class="col-lg-12">
        <v-card
          :loading="loading"
          flat
        >
          <v-card-title>
            {{ $t('Documents KYC') }}
            <v-spacer />
            <v-dialog
              v-model="dialog"
              max-width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  value="right"
                  v-bind="attrs"
                  color="primary"
                  v-on="on"
                >
                  {{ $t('Ajouter un document KYC') }}
                </v-btn>
              </template>
              <base-card>
                <v-card-title>
                  {{ $t('Créer un nouveau document KYC') }}
                  <v-spacer />
                  <v-btn
                    class="mt-4"
                    absolute
                    right
                    icon
                    color="secondary"
                    text
                    @click="dialog = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-alert
                    dismissible
                    type="info"
                  >
                    {{ $t('Notez que si vous envoyez plusieurs informations pour le même document (par exemple le recto et le verso d\'un document), vous devez envoyer un document KYC qui contient plusieurs pages puisque chaque document est traité de manière indépendante') }}
                  </v-alert>
                  <v-row>
                    <v-col cols="12">
                      <v-select

                        v-model="item.type"
                        :items="docTypes"
                        item-text="label"
                        item-value="id"
                        outlined
                        dense
                        :label="$t('Type de document KYC')"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-file-input
                        v-model="item.kycdocument"
                        outlined
                        dense
                        :label="$t('Consulter le document KYC...')"
                        @change="onFileChanged"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    :loading="loading"
                    color="primary"
                    @click="onSubmit"
                  >
                    {{ $t("Ajouter ce document") }}
                  </v-btn>
                </v-card-actions>
              </base-card>
            </v-dialog>
          </v-card-title>
          <v-divider />
          <v-simple-table class="table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Type</th>
                <th>Status</th>
                <!--                <th>Action</th>-->
              </tr>
            </thead>

            <tbody>
              <tr v-for="(kyc, $index) in kycdocs">
                <td>{{ kyc.Id }}</td>
                <td>{{ kyc.Type }}</td>
                <td>{{ kyc.Status }}</td>
                <td>
                  <!--                  <button
                    v-if="kyc.Status === 'CREATED' && false"
                    class="btn btn-primary"
                  >
                    {{ $t('Soumetre le doc.') }}
                  </button>-->
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </div>
    </div>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="snackBarColor"
    >
      {{ snackBarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn

          text
          v-bind="attrs"
          @click="isSnackbarBottomVisible = false"
        >
          {{ $t('Fermer') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import { i18n } from '@/plugins/i18n'
  import { mapActions, mapGetters, mapState } from 'vuex'

  import FileUpload from '../../components/file_upload.vue'
  import PhotoWidget from './PhotoWidget.vue'

  export default {
    components: {
      FileUpload,
      PhotoWidget,
    },

    data: vm => {
      return {
        dialog: false,
        dateMenu: false,
        activePicker: null,
        valid: false,
        checkbox: false,
        loading: false,
        isPasswordVisible: false,
        name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        isSnackbarBottomVisible: false,
        snackBarMessage: '',
        snackBarColor: 'success',
        errorMessages: { name: 'Est requis' },
        salutationRules: [v => !!v || i18n.t('Requis.')],
        termsRules: [v => !!v || i18n.t('Accepter les conditions générales')],
        descriptionRules: [v => !!v || i18n.t('Le nom est requis')],
        nameRules: [v => !!v || i18n.t('Le nom est requis')],
        countryRules: [v => !!v || i18n.t('Le Pays est requis')],
        cityRules: [v => !!v || i18n.t('La ville est requis')],
        firstNameRules: [v => !!v || i18n.t('Le prénom est requis')],
        lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis')],
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],
        passwordRules: [v => !!v || i18n.t('Le mot de passe est requis')],
        telRules: [v => !!v || i18n.t('Le numéro de téléphone est requis.')],
        addressRules: [v => !!v || i18n.t('L\'adresse 1 est requise.')],
        dateRules: [v => !!v || i18n.t('La date de creation est requise.')],
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        user: {
          dob: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        },
        item: {

        },
        kycdocs: [],
        form: null,
      }
    },
    computed: {
      ...mapGetters(['getCartProducts', 'unreadNotifications']),
      ...mapState({
        userData: 'user',
        salutations: 'salutations',
        countries: 'countries',
        displayNotification: 'display_notification',
      }),
      computedDateFormatted () {
        return this.formatDate(this.user.user.dob || this.date)
      },
      date () {
        return this.user.dob
      },
      docTypes () {
        if (this.user && this.user.is_pro_seller) {
          return [
            {
              id: 'IDENTITY_PROOF',
              label: this.$t('IDENTITY PROOF'),
            },
            {
              id: 'REGISTRATION_PROOF',
              label: this.$t('REGISTRATION PROOF'),
            },
            {
              id: 'ARTICLES_OF_ASSOCIATION',
              label: this.$t('ARTICLES OF ASSOCIATION'),
            },
            {
              id: 'SHAREHOLDER_DECLARATION',
              label: this.$t('SHAREHOLDER DECLARATION'),
            },
          ]
        } else {
          return [
            {
              id: 'IDENTITY_PROOF',
              label: this.$t('IDENTITY PROOF'),
            },
            {
              id: 'ADDRESS_PROOF',
              label: this.$t('ADDRESS PROOF'),
            },
          ]
        }
      },
    },
    watch: {
      userData (val) {
        this.user = val
      },
      date (val) {
        this.dateFormatted = this.formatDate(this.user.user.dob || this.date)
      },
      dateMenu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
    },
    created () {
      this.user = this.$store.state.user
      this.getData()
    },
    methods: {
      ...mapActions(['pushItem']),

      onFileChanged (file) {
        if (file) {
          this.form = new FormData()
          this.form.append('kycdocument', file, file.name)
        }
      },
      addfile () {

      },
      save (date) {
        this.$refs.dateMenu.save(date)
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${month}/${day}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      submitkyc (el, index) {
        /*  var $target = $(el.currentTarget);
        $target.html("... Soumission du document en cours") */

        var kycdoc = this.kycdocs[index]

        const data = new FormData()
        data.append('userId', '')

        const URL = 'kycdocument/submit?Id=' + kycdoc.Id
        this.loading = true
        axios.post(
          URL,
          data,
        ).then(
          response => {
            this.loadingpage = false
            console.log(response)
            this.message = response.data.message

            this.dialog = false

            // $target.before().remove();
            // $target.remove();
          },
        ).catch((error) => {
          console.log(error)
          this.loadingpage = false
        })
      },
      onSubmit () {
        if (!this.item.type) {
          this.toast(this.$t('Please select a document type'), 'red')
          return false
        }
        if (!this.item.kycdocument) {
          this.toast(this.$t('Please select a document type'), 'red')
          return false
        }
        this.loading = true
        // eslint-disable-next-line no-undef
        this.form.append('Type', this.item.type)
        if (this.item.id) {
          this.form.append('Id', this.item.id)
        }

        axios({
          method: 'post',
          url: 'kycdocument/uploadfile',
          data: this.form,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        }).catch(error => {
          // this.loading = false
          this.loading = false
          console.log(error)
          // eslint-disable-next-line consistent-return
        }).then((response) => {
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              this.errorMessages = { ...response.data.errors }
            } else {
              this.toast(response.data.message, 'red')

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return false
          }
          this.kycdocs = response.data
          this.toast(this.$t('Informations enregistrées avec succès'), 'success')
          this.dialog = false
        })
          .then(() => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to Register!')
            console.log('error :>> ', error.response || '')
            // this.errorMessages = error.response.data.error
          })
      },
      getData () {
        this.loading = true
        // eslint-disable-next-line no-undef
        axios({
          method: 'get',
          url: '/kycdocuments',
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        }).catch(error => {
          // this.loading = false
          this.loading = false
          console.log(error)
          // eslint-disable-next-line consistent-return
        }).then((response) => {
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              this.errorMessages = { ...response.data.errors }
            } else {
              this.toast(response.data.message, 'red')

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return false
          }
          this.kycdocs = response.data
        })
          .then(() => {
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            // TODO: Next Update - Show notification
            console.error('Oops, Unable to Register!')
            console.log('error :>> ', error.response || '')
            // this.errorMessages = error.response.data.error
          })
      },
      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },
    },
  }
</script>

<style lang="scss" scoped>
.sign-up-card {
  width: 500px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  margin: 2rem auto;
  box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
  @media(max-width: 500px) {
    width: 100%;
  }

  .sign-up-card-container {
    padding: 3rem 3.75rem 0px;
    @media(max-width: 500px) {
      padding: 3rem 1rem 0px;
    }
  }
}

.box-sidebar {
  .sidebar-slider {
    .v-messages {
      display: none;
    }
  }
}
</style>
