import { render, staticRenderFns } from "./ubodelarations.vue?vue&type=template&id=3aba3642&scoped=true"
import script from "./ubodelarations.vue?vue&type=script&lang=js"
export * from "./ubodelarations.vue?vue&type=script&lang=js"
import style0 from "./ubodelarations.vue?vue&type=style&index=0&id=3aba3642&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3aba3642",
  null
  
)

export default component.exports