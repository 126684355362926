<template>
  <div class="box-container" v-if="$store.state.user">
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        lg="12"
      >
        <div class="d-flex justify-space-between flex-wrap mb-5">
          <div class="d-flex align-center ">
            <v-avatar
              tile
              size="25"
              class="me-3"
            >
              <img
                src="@/assets/images/icons/user_filled.svg"
                alt=""
              >
            </v-avatar>
            <h2 class="mb-0">
              {{ $t("Modifier le profil") }}
            </h2>
          </div>
          <v-btn
            to="/profile"
            outlined
            color="primary"
            class="text-capitalize font-600"
          >
            {{ $t("Retour au profil") }}
          </v-btn>
        </div>
        <v-row>
          <v-col cols="12">
            <v-tabs
              v-model="tab"
              color="primary"
              grow
            >
              <v-tab
                key="info"
                href="#info"
              >
                {{ $t("Informations") }}
                <!--  {{ $t("Informations utilisateur") }} -->
              </v-tab>
              <v-tab
                v-if="$store.state.user.is_seller || $store.state.user.is_pro_seller"
                key="kyc_docs"
                href="#kyc_docs"
              >
                {{ $t("KYC Document") }}
              </v-tab>
              <v-tab
                v-if="$store.state.user.is_seller || $store.state.user.is_pro_seller"
                key="info-pro"
                href="#info-pro"
              >
                {{ $t("Vendeur pro") }}
              </v-tab>
              <v-tab
                v-if="$store.state.login && $store.state.user.is_pro_seller"
                key="ubo"
                href="#ubo"
              >
                {{ $t("UBO Declaration") }}
              </v-tab>
              <v-tab
                key="email"
                href="#email"
              >
                {{ $t("Changer d'e-mail") }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item
                key="info"
                value="info"
              >
                <base-card>
                  <info-form />
                </base-card>
              </v-tab-item>
              <v-tab-item
                key="kyc_docs"
                value="kyc_docs"
              >
                <base-card>
                  <kycslist />
                </base-card>
              </v-tab-item>
              <v-tab-item
                key="info-pro"
                value="info-pro"
              >
                <base-card>
                  <info-pro />
                </base-card>
              </v-tab-item>
              <v-tab-item
                v-if="$store.state.login && $store.state.user.is_pro_seller"
                key="ubo"
                value="ubo"
              >
                <base-card>
                  <ubodelarations />
                </base-card>
              </v-tab-item>
              <v-tab-item
                key="email"
                value="email"
              >
                <base-card>
                  <div class="pa-5">
                    <v-row class="align-center justify-center ali">
                      <v-col
                        cols="12"
                        md="6"
                        lg="6"
                      >
                        <change-email />
                      </v-col>
                    </v-row>
                  </div>
                </base-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import DashbordSidebar from '@/components/DashboardSidebar'
  import ChangeEmail from '@/views/profile/ChangeEmail'
  import InfoForm from '@/views/profile/InfoForm'
  import InfoPro from './InfoPro.vue'
  import Kycslist from './kycslist.vue'
  import Ubodelarations from './ubodelarations.vue'
  export default {
    components: {
      Ubodelarations,
      Kycslist,
      InfoPro,
      DashbordSidebar,
      InfoForm,
      ChangeEmail,

    },
    data () {
      return {
        tab: null,
        isSidebar: false,
        rules: [
          value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
        ],

      }
    },

  }

</script>
